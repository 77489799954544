import { createMemoryHistory, createRouter } from 'vue-router'

import LoginView from '../views/LoginView.vue'
import ChatView from '../views/ChatView.vue'

const routes = [
  {
    name: 'ChatView',
    path: '/',
    component: ChatView
  },
  {
     name: 'LoginView',
     path: '/login',
     component: LoginView,
     meta: {
       public: true
     }
  },
]

const router = createRouter({
  history: createMemoryHistory(),
  routes,
})

router.beforeEach((to) => {
  const authToken = localStorage.getItem('userToken')

  if (!to?.meta?.public & !authToken) {
    return { name: 'LoginView' }
  }
})

export default router