<template>
  <div class="login-view">
    <div id="buttonDiv"></div>
    <p>by invitation only</p>
    <p v-if="errorMessage"><b>Error:</b> {{ errorMessage }}</p>
  </div>
</template>

<script>
import verifyTokenWithServer from '@/utils/verifyAuthToken'

export default {
  name: 'LoginView',
  components: {
  },
  data() {
    return {
      errorMessage: ''
    };
  },
  created() {
  },
  async mounted() {
    if (window.google) {
      this.initGoogleAuth()

      const authToken = localStorage.getItem('userToken')

      if (authToken) {
        this.$router.push({ name: 'ChatView' })
      }
    } else {
      window.onload = () => {
        this.initGoogleAuth()
      }
    }
  },
  methods: {
    initGoogleAuth() {
      window.google.accounts.id.initialize({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        callback: this.handleCredentialResponse,
        auto_select: true,
      })

      window.google.accounts.id.renderButton(
      document.getElementById("buttonDiv"),
        { theme: "outline", size: "large" }  // customization attributes
      )
    },
    async handleCredentialResponse(response) {
      try {
        const isVerified = await verifyTokenWithServer(response.credential)

        if (isVerified === true) {
          this.$router.push({ name: 'ChatView' })
        }
      } catch (error) {
        this.errorMessage = error
      }
    },
  },
};
</script>

<style>
html, body, #app, .app-frame {
  height: 100vh !important;
  max-height: 100vh;
  overflow: hidden;
  padding: 0 !important;
  margin: 0 !important;
}

.app-frame {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background-color: rgba(40, 40, 40); */
  background-color: #2e3037;
  flex-direction: column;
}

.messages-pane {
  flex-grow: 1;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  color: white;
  padding: 0 15px;
  overflow: hidden;
  /* overflow-y: scroll; */
}

.messages-container {
  display: flex;
  flex-direction: column-reverse;
  overflow-y: scroll;
}

.messages-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.message-row {
  display: flex;
  flex-direction: row;
}

.message-row.user {
  justify-content: end;
}

.message-content {
  background: rgba(200, 200, 200, 0.4);
  padding: 8px 12px;
  border-radius: 16px;
  display: inline-flex;
  align-self: flex-end;
  font-family: system-ui;
  font-size: 16px;
  max-width: 80%;
  /* word-break: */
  white-space: pre-wrap;
}

.message-content.typing-dots {
  margin-bottom: 15px;
  padding-top: 12px;
  padding-bottom: 4px;
  padding-left: 28px;
  padding-right: 4px;
  min-width: 40px;
}

.message-row.user > .message-content {
  background: rgba(56, 118, 191, 1)
}

.input-pane {
  background: #1b1e21;
  display: flex;
  flex-direction: row;
  padding: 15px;
  gap: 15px;
  border-top: 1px solid rgb(74, 74, 74);
}

input {
  color: white;
  flex: 1 1 auto;
  background: none;
  border: 1px solid rgb(103, 103, 103);
  width: 100%;
  /* border: none; */
  padding: 8px 12px;
  border-radius: 16px;
  font-size: 16px;
}

button {
  border: none;
  padding: 8px 12px;
  border-radius: 16px;
  background: #7250B8;
  color: white;
  font-size: 16px;
}

.dot-typing {
  position: relative;
  left: -9999px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.5);
  box-shadow: 9984px 0 0 0 rgba(255, 255, 255, 0.5), 9999px 0 0 0 rgba(255, 255, 255, 0.5), 10014px 0 0 0 rgba(255, 255, 255, 0.5);
  animation: dot-typing 1.5s infinite linear;
}

@keyframes dot-typing {
  0% {
    box-shadow: 9984px 0 0 0 rgba(255, 255, 255, 0.5), 9999px 0 0 0 rgba(255, 255, 255, 0.5), 10014px 0 0 0 rgba(255, 255, 255, 0.5);
  }
  16.667% {
    box-shadow: 9984px -8px 0 0 rgba(255, 255, 255, 0.4), 9999px 0 0 0 rgba(255, 255, 255, 0.5), 10014px 0 0 0 rgba(255, 255, 255, 0.5);
  }
  33.333% {
    box-shadow: 9984px 0 0 0 rgba(255, 255, 255, 0.5), 9999px 0 0 0 rgba(255, 255, 255, 0.5), 10014px 0 0 0 rgba(255, 255, 255, 0.5);
  }
  50% {
    box-shadow: 9984px 0 0 0 rgba(255, 255, 255, 0.5), 9999px -8px 0 0 rgba(255, 255, 255, 0.4), 10014px 0 0 0 rgba(255, 255, 255, 0.5);
  }
  66.667% {
    box-shadow: 9984px 0 0 0 rgba(255, 255, 255, 0.5), 9999px 0 0 0 rgba(255, 255, 255, 0.5), 10014px 0 0 0 rgba(255, 255, 255, 0.5);
  }
  83.333% {
    box-shadow: 9984px 0 0 0 rgba(255, 255, 255, 0.5), 9999px 0 0 0 rgba(255, 255, 255, 0.5), 10014px -8px 0 0 rgba(255, 255, 255, 0.4);
  }
  100% {
    box-shadow: 9984px 0 0 0 rgba(255, 255, 255, 0.5), 9999px 0 0 0 rgba(255, 255, 255, 0.5), 10014px 0 0 0 rgba(255, 255, 255, 0.5);
  }
}

.login-view {
  text-align: center;
}

#buttonDiv {
  display: inline-block;
  margin-top: calc(50vh - 40px)
}

</style>