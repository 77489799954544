const verifyTokenWithServer = async(idToken) => {
  try {
    const response = await fetch(`https://${process.env.VUE_APP_SERVER}/verify-auth-token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token: idToken }),
    });

    const data = await response.json();

    if (data.verified) {
      localStorage.setItem('userToken', data.token)
      return true
    } else {
      console.error('User verification failed.')
      return false
    }
  } catch (error) {
    console.error('Error verifying user:', error);
    return false
  }
}

export default verifyTokenWithServer