<template>
  <div id="app">
    <main>
      <RouterView />
    </main>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      ws: null,
      messages: [],
      message: '',
      inputDisabled: true,
      typingDots: false,
      auth: false,
    };
  },
  created() {
  },
  mounted() {},
  methods: {
  },
};
</script>

<style>
html, body, #app, .app-frame {
  font-family: system-ui;
  height: 100vh !important;
  max-height: 100vh;
  overflow: hidden;
  padding: 0 !important;
  margin: 0 !important;
  background-color: #2e3037;
  color: white;
}

.app-frame {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background-color: rgba(40, 40, 40); */
  background-color: #2e3037;
  flex-direction: column;
}

.messages-pane {
  flex-grow: 1;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  color: white;
  padding: 0 15px;
  overflow: hidden;
  /* overflow-y: scroll; */
}

.messages-container {
  display: flex;
  flex-direction: column-reverse;
  overflow-y: scroll;
}

.messages-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.message-row {
  display: flex;
  flex-direction: row;
}

.message-row.user {
  justify-content: end;
}

.message-content {
  background: rgba(200, 200, 200, 0.4);
  padding: 8px 12px;
  border-radius: 16px;
  display: inline-flex;
  align-self: flex-end;
  font-family: system-ui;
  font-size: 16px;
  max-width: 80%;
  /* word-break: */
  white-space: pre-wrap;
}

.message-content.typing-dots {
  margin-bottom: 15px;
  padding-top: 12px;
  padding-bottom: 4px;
  padding-left: 28px;
  padding-right: 4px;
  min-width: 40px;
}

.message-row.user > .message-content {
  background: rgba(56, 118, 191, 1)
}

.input-pane {
  background: #1b1e21;
  display: flex;
  flex-direction: row;
  padding: 15px;
  gap: 15px;
  border-top: 1px solid rgb(74, 74, 74);
}

input {
  color: white;
  flex: 1 1 auto;
  background: none;
  border: 1px solid rgb(103, 103, 103);
  width: 100%;
  /* border: none; */
  padding: 8px 12px;
  border-radius: 16px;
  font-size: 16px;
}

button {
  border: none;
  padding: 8px 12px;
  border-radius: 16px;
  background: #7250B8;
  color: white;
  font-size: 16px;
}

.dot-typing {
  position: relative;
  left: -9999px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.5);
  box-shadow: 9984px 0 0 0 rgba(255, 255, 255, 0.5), 9999px 0 0 0 rgba(255, 255, 255, 0.5), 10014px 0 0 0 rgba(255, 255, 255, 0.5);
  animation: dot-typing 1.5s infinite linear;
}

@keyframes dot-typing {
  0% {
    box-shadow: 9984px 0 0 0 rgba(255, 255, 255, 0.5), 9999px 0 0 0 rgba(255, 255, 255, 0.5), 10014px 0 0 0 rgba(255, 255, 255, 0.5);
  }
  16.667% {
    box-shadow: 9984px -8px 0 0 rgba(255, 255, 255, 0.4), 9999px 0 0 0 rgba(255, 255, 255, 0.5), 10014px 0 0 0 rgba(255, 255, 255, 0.5);
  }
  33.333% {
    box-shadow: 9984px 0 0 0 rgba(255, 255, 255, 0.5), 9999px 0 0 0 rgba(255, 255, 255, 0.5), 10014px 0 0 0 rgba(255, 255, 255, 0.5);
  }
  50% {
    box-shadow: 9984px 0 0 0 rgba(255, 255, 255, 0.5), 9999px -8px 0 0 rgba(255, 255, 255, 0.4), 10014px 0 0 0 rgba(255, 255, 255, 0.5);
  }
  66.667% {
    box-shadow: 9984px 0 0 0 rgba(255, 255, 255, 0.5), 9999px 0 0 0 rgba(255, 255, 255, 0.5), 10014px 0 0 0 rgba(255, 255, 255, 0.5);
  }
  83.333% {
    box-shadow: 9984px 0 0 0 rgba(255, 255, 255, 0.5), 9999px 0 0 0 rgba(255, 255, 255, 0.5), 10014px -8px 0 0 rgba(255, 255, 255, 0.4);
  }
  100% {
    box-shadow: 9984px 0 0 0 rgba(255, 255, 255, 0.5), 9999px 0 0 0 rgba(255, 255, 255, 0.5), 10014px 0 0 0 rgba(255, 255, 255, 0.5);
  }
}

</style>