<template>
  <div id="app" class="app-frame">
      <div class="messages-pane">
        <div class="messages-container">
          <div class="messages-wrapper">
            <template v-for="(msg, index) in messages" :key="index">
              <div v-if="msg.message" class="message-row" :class="{ user: msg.role == 'user' }">
                <pre class="message-content" v-text="msg.message" />
              </div>
            </template>
            <div class="message-row">
              <div class="message-content typing-dots" v-show="typingDots">&nbsp;<div class="dot-typing" /></div>
            </div>
          </div>
        </div>
      </div>
      <div class="input-pane">
        <input v-model="message" placeholder="Type a message..." :disabled="inputDisabled" @keyup.enter="sendMessage">
        <button @click="sendMessage">Send</button>
        <button @click="logout">Logout</button>
        <!-- <button @click="enableNotifications">Notifications</button> -->
      </div>
    </div>
</template>

<script>
export default {
  name: 'ChatView',
  components: {
  },
  data() {
    return {
      ws: null,
      messages: [],
      message: '',
      inputDisabled: true,
      typingDots: false,
      auth: false,
    };
  },
  created() {
    const authToken = localStorage.getItem('userToken')

    this.connect(authToken)

    // this.establishWebSocketConnection(authToken)
  },
  mounted() {
  },
    // window.handleCredentialResponse = this.handleCredentialResponse;
    // window.google.accounts.id.initialize({
    //   client_id: '748112992332-nuooiaqe026vuuej64srtdpdnmdugkt1.apps.googleusercontent.com',
    //   callback: window.handleCredentialResponse
    // });
    // window.google.accounts.id.prompt();
  methods: {
    logout () {
      localStorage.setItem('userToken', '')
      this.ws.close()
    },
    // async sendSubscriptionToBackend(subscription) {
    //   try {
    //     await fetch('https://localhost:3000/subscribe', {
    //       method: 'POST',
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //       body: JSON.stringify(subscription),
    //     });
    //     console.log('Subscription sent to the server.');
    //   } catch (error) {
    //     console.error('Failed to send subscription to server: ', error);
    //   }
    // },
    // urlBase64ToUint8Array(base64String) {
    //   const padding = '='.repeat((4 - base64String.length % 4) % 4);
    //   const base64 = (base64String + padding)
    //     .replace(/-/g, '+')
    //     .replace(/_/g, '/');

    //   const rawData = window.atob(base64);
    //   const outputArray = new Uint8Array(rawData.length);

    //   for (let i = 0; i < rawData.length; ++i) {
    //     outputArray[i] = rawData.charCodeAt(i);
    //   }
    //   return outputArray;
    // },
    // async subscribeToPushNotifications() {
    //     // Check if the browser supports notifications
    //     if ('Notification' in window) {
    //       try {
    //         const permission = await Notification.requestPermission();
    //         if (permission === 'granted') {
    //           console.log('Notification permission granted.');
    //           // Notification permission granted

    //           if ('serviceWorker' in window.navigator && 'PushManager' in window) {
    //             console.log('aaaarg')
    //             try {
    //               const registration = await window.navigator.serviceWorker.ready;
    //               console.log('regis')
    //               const subscription = await registration.pushManager.subscribe({
    //                 userVisibleOnly: true,
    //                 applicationServerKey: this.urlBase64ToUint8Array('BI7_ecPZdqR-LdJNiPd2FM_-NaSQuVKaYaOdtfPi1CMgKmxKwomtocPJqJq-2LA-RTyg6-Mwkz11xA6tRDmC06I'),
    //               });
    //               console.log('User is subscribed:', subscription);
    //               return subscription;
    //             } catch (error) {
    //               console.error('Failed to subscribe the user: ', error);
    //             }
    //         } else {
    //           console.warn('Push messaging is not supported');
    //         }
    //         } else {
    //           console.log('Notification permission denied.');
    //           // Notification permission denied
    //         }
    //       } catch (error) {
    //         console.error('Failed to request notification permission', error);
    //       }
    //     } else {
    //       console.log('This browser does not support notifications.');
    //     }
    // },
    // async enableNotifications() {
    //   const subscription = await this.subscribeToPushNotifications();
    //   if (subscription) {
    //     await this.sendSubscriptionToBackend(subscription);
    //   }
    // },
    connect(token) {
      this.ws = new WebSocket(`wss://${process.env.VUE_APP_SERVER}?token=${token}`)

      this.ws.onopen = async () => {
        // this.ws.send(JSON.stringify('[SYSTEM: Connection established]'));

        console.log('Connection established')
        this.inputDisabled = false
      }

      this.ws.onclose = () => {
        console.log('onclose')
        // localStorage.setItem('userToken', '')
        this.$router.push({ name: 'LoginView' })
      }

      this.ws.onmessage = (event) => {
        console.log('onMessage', event)
        const data = JSON.parse(event.data);
        console.log('data', data, data?.delta)
        switch(data.type) {
          case 'connected':
            console.log('WebSockets connected')
            break
          case 'runCreated':
            this.typingDots = true
            break
          case 'messageLog':
            this.messages = JSON.parse(data.messages).reverse().map(msg => {
              // console.log('msg', msg)
              return {
                role: msg.role,
                message: msg?.content[0]?.text?.value
              }
            })
            break
          case 'runCompleted':
            this.inputDisabled = false
            break
          case 'textCreated':
            console.log('textCreated')
            this.messages.push({
              "role": "assistant",
              "message": ""
            });
            break;
          case 'textDelta':
            if (data?.delta?.content) {
              this.typingDots = false

              console.log('data', data.delta.content[0].text.value)
              
              this.messages[this.messages.length - 1].message += data.delta.content[0].text.value
            }
            // this.messages.push("Delta > " + data.textDelta);
            break;
          case 'toolCallCreated':
            this.messages.push("Tool Call > " + data.toolCall.type);
            break;
          case 'toolCallDelta':
            // Process toolCallDelta as needed
            break;
        }
      };
    },
    async sendMessage() {
      // console.log()
      this.typingDots = true
      this.inputDisabled = true;
      this.messages.push({
          "role": "user",
          "message": this.message
        });
      this.ws.send(JSON.stringify(this.message))
      this.message = ''
    }
  },
};
</script>

<style>
html, body, #app, .app-frame {
  height: 100vh !important;
  max-height: 100vh;
  overflow: hidden;
  padding: 0 !important;
  margin: 0 !important;
}

.app-frame {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background-color: rgba(40, 40, 40); */
  background-color: #2e3037;
  flex-direction: column;
}

.messages-pane {
  flex-grow: 1;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  color: white;
  padding: 0 15px;
  overflow: hidden;
  /* overflow-y: scroll; */
}

.messages-container {
  display: flex;
  flex-direction: column-reverse;
  overflow-y: scroll;
}

.messages-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.message-row {
  display: flex;
  flex-direction: row;
}

.message-row.user {
  justify-content: end;
}

.message-content {
  background: rgba(200, 200, 200, 0.4);
  padding: 8px 12px;
  border-radius: 16px;
  display: inline-flex;
  align-self: flex-end;
  font-family: system-ui;
  font-size: 16px;
  max-width: 80%;
  /* word-break: */
  white-space: pre-wrap;
}

.message-content.typing-dots {
  margin-bottom: 15px;
  padding-top: 12px;
  padding-bottom: 4px;
  padding-left: 28px;
  padding-right: 4px;
  min-width: 40px;
}

.message-row.user > .message-content {
  background: rgba(56, 118, 191, 1)
}

.input-pane {
  background: #1b1e21;
  display: flex;
  flex-direction: row;
  padding: 15px;
  gap: 15px;
  border-top: 1px solid rgb(74, 74, 74);
}

input {
  color: white;
  flex: 1 1 auto;
  background: none;
  border: 1px solid rgb(103, 103, 103);
  width: 100%;
  /* border: none; */
  padding: 8px 12px;
  border-radius: 16px;
  font-size: 16px;
}

button {
  border: none;
  padding: 8px 12px;
  border-radius: 16px;
  background: #7250B8;
  color: white;
  font-size: 16px;
}

.dot-typing {
  position: relative;
  left: -9999px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.5);
  box-shadow: 9984px 0 0 0 rgba(255, 255, 255, 0.5), 9999px 0 0 0 rgba(255, 255, 255, 0.5), 10014px 0 0 0 rgba(255, 255, 255, 0.5);
  animation: dot-typing 1.5s infinite linear;
}

@keyframes dot-typing {
  0% {
    box-shadow: 9984px 0 0 0 rgba(255, 255, 255, 0.5), 9999px 0 0 0 rgba(255, 255, 255, 0.5), 10014px 0 0 0 rgba(255, 255, 255, 0.5);
  }
  16.667% {
    box-shadow: 9984px -8px 0 0 rgba(255, 255, 255, 0.4), 9999px 0 0 0 rgba(255, 255, 255, 0.5), 10014px 0 0 0 rgba(255, 255, 255, 0.5);
  }
  33.333% {
    box-shadow: 9984px 0 0 0 rgba(255, 255, 255, 0.5), 9999px 0 0 0 rgba(255, 255, 255, 0.5), 10014px 0 0 0 rgba(255, 255, 255, 0.5);
  }
  50% {
    box-shadow: 9984px 0 0 0 rgba(255, 255, 255, 0.5), 9999px -8px 0 0 rgba(255, 255, 255, 0.4), 10014px 0 0 0 rgba(255, 255, 255, 0.5);
  }
  66.667% {
    box-shadow: 9984px 0 0 0 rgba(255, 255, 255, 0.5), 9999px 0 0 0 rgba(255, 255, 255, 0.5), 10014px 0 0 0 rgba(255, 255, 255, 0.5);
  }
  83.333% {
    box-shadow: 9984px 0 0 0 rgba(255, 255, 255, 0.5), 9999px 0 0 0 rgba(255, 255, 255, 0.5), 10014px -8px 0 0 rgba(255, 255, 255, 0.4);
  }
  100% {
    box-shadow: 9984px 0 0 0 rgba(255, 255, 255, 0.5), 9999px 0 0 0 rgba(255, 255, 255, 0.5), 10014px 0 0 0 rgba(255, 255, 255, 0.5);
  }
}

</style>